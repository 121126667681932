import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../assets/logo.png';

const Header = ({ user, onLogout }) => {
  return (
    <Navbar style={{ backgroundColor: '#153a42' }} variant="dark" expand="lg" collapseOnSelect>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand className="d-flex align-items-center">
            <img
              src={logo}
              alt="PullBill Logo"
              width="80"
              height="80"
              className="d-inline-block align-top"
            />{' '}
            <h5 className="mb-0 ms-2 align-middle">PullBill</h5>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/api-docs">
              <Nav.Link>API Docs</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/templates">
              <Nav.Link>Templates</Nav.Link>
            </LinkContainer>
            {user ? (
              <>
                <LinkContainer to="/dashboard">
                  <Nav.Link>Dashboard</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={onLogout}>Logout</Nav.Link>
              </>
            ) : (
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
