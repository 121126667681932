import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Container, Card, Button } from 'react-bootstrap';
import appStoreButton from '../assets/appStore.png'; 
import playStoreButton from '../assets/playStore.png'; 

const ApiDocumentation = () => {
  const swaggerConfig = require('../SwaggerConfig.json');

  return (
    <Container style={{ padding: '20px' }}>
      <h1>PullBill API Documentation</h1>
      <section>
        <h2>Overview</h2>
        <p>The PullBill API allows merchants to manage receipts, customer data, and merchant information. By integrating with the PullBill API, merchants can seamlessly send digital receipts to their customers, ensuring a smooth and efficient transaction process.</p>
      </section>
      <section>
        <h2>Getting Started</h2>
        <Card>
          <Card.Body>
            <h3>Register on PullBill Merchant App</h3>
            <p>Before using the PullBill API, you need to register on the PullBill Merchant app. Download the app from the respective stores:</p>
            <div className="text-center">
              <Button variant="link" href="https://apps.apple.com/us/app/pullbill-merchant/id6502247380" className="m-2">
                <img src={appStoreButton} alt="App Store" style={{ width: '150px' }} />
              </Button>
              <Button variant="link" href="https://play.google.com/store/apps/details?id=com.pbmerchant.pb" className="m-2">
                <img src={playStoreButton} alt="Google Play" style={{ width: '150px' }} />
              </Button>
            </div>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <h3>Complete Customization Data</h3>
            <p>After registration, complete your customization data on the PullBill Merchant app. This includes setting up your business details, default tax rates, and other preferences.</p>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <h3>Login to PullBill Merchant Web Portal</h3>
            <p>Log in to the PullBill Merchant web portal to obtain your API key.</p>
            <a href="https://pullbill.com/login" target="_blank" rel="noopener noreferrer">Login Page</a>
          </Card.Body>
        </Card>
        <br />
      </section>
      <section>
        <h2>Using the API</h2>
        <Card>
          <Card.Body>
            <h3>Authentication</h3>
            <p>All API calls require an API key for authentication. Include the API key in the header of each request:</p>
            <pre><code>{`Authorization: Bearer <your-api-key>`}</code></pre>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <h3>Customer Lookup API</h3>
            <p>Use the Customer Lookup API to retrieve customer information by providing their mobile number, country code, or unique ID:</p>
            <div style={{ border: '1px solid #e3e3e3', borderRadius: '4px', padding: '10px', marginBottom: '20px' }}>
              <SwaggerUI spec={swaggerConfig} />
            </div>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <h3>Add Receipt API</h3>
            <p>Use the Add Receipt API to store a new receipt in the Firebase database:</p>
            <div style={{ border: '1px solid #e3e3e3', borderRadius: '4px', padding: '10px', marginBottom: '20px' }}>
              <SwaggerUI spec={swaggerConfig} />
            </div>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <h3>Sending Receipts to Customers</h3>
            <p>Once you have retrieved customer information and prepared the receipt data, you can send receipts to PullBill customers as follows:</p>
            <ol>
              <li><strong>Customer Search:</strong> Use the Customer Lookup API to get the customer's UUID.</li>
              <li><strong>Prepare Receipt Data:</strong> Collect all necessary details such as items, subtotal, tax, total, and any adjustments.</li>
              <li><strong>Add Receipt:</strong> Use the Add Receipt API to send receipt to customer. Ensure that you include the customer UUID and merchant UUID in the request.</li>
            </ol>
            <p>This process ensures that receipts are shared to customers through the PullBill platform.</p>
            <p>Merchants can view the receipts using the direct endpoint:</p>
            <pre><code>{`https://pullbill.com/receipt/{receiptId}`}</code></pre>
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default ApiDocumentation;
