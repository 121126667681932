import React from 'react';
import { Link } from 'react-router-dom';
import template1 from '../assets/Template1.png';
import template2 from '../assets/Template2.png';
import template3 from '../assets/Template3.png';
import template4 from '../assets/Template4.png';
import template5 from '../assets/Template5.png';
import template6 from '../assets/Template6.png';

const receipts = [
    { id: 'CxydSG8i03nCu4OQUVP3', template: 'Template 1', thumbnail: template1 },
    { id: 'DMaNGQJkd7vW4TntatqE', template: 'Template 2', thumbnail: template2 },
    { id: 'VS1bYlzMUArLPXqUFgc8', template: 'Template 3', thumbnail: template3 },
    { id: 'wpTj4FwQpAluPbITyM9o', template: 'Template 4', thumbnail: template4 },
    { id: 'vL50Cm4qOIqzNmLzOqaF', template: 'Template 5', thumbnail: template5 },
    { id: '7vIJydybCshFHFddBgD1', template: 'Template 6', thumbnail: template6 },
];

const TemplatePage = () => {
  return (
    <div style={styles.templatePage}>
      <h1 style={styles.header}>Receipt Templates</h1>
      <div style={styles.cardContainer}>
        {receipts.map((receipt) => (
          <div key={receipt.id} style={styles.card}>
            <img src={receipt.thumbnail} alt={receipt.template} style={styles.thumbnail} />
            <div style={styles.cardContent}>
              <h2 style={styles.cardTitle}>{receipt.template}</h2>
              <div style={styles.buttonGroup}>
                <Link to={`/receipt-template/${receipt.id}`} style={styles.btn}>
                  Preview
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  templatePage: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center',
    color: '#153a42',
    marginBottom: '40px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px',
    padding: '20px',
    width: 'calc(33% - 40px)',
    boxSizing: 'border-box',
    transition: 'transform 0.2s',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardTitle: {
    color: '#153a42',
    marginBottom: '20px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    backgroundColor: '#153a42',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    textDecoration: 'none',
    transition: 'background-color 0.2s',
  },
  thumbnail: {
    width: '100%',
    height: '300px',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  '@media (max-width: 768px)': {
    cardContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      width: '90%',
      marginBottom: '20px',
    },
  },
  '@media (max-width: 480px)': {
    cardContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      width: '100%',
      marginBottom: '20px',
    },
    thumbnail: {
      height: '200px',
    },
  },
};


export default TemplatePage;
