import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col>

          <h3>Introduction</h3>
          <p>
            Welcome to PullBill. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services. We are committed to safeguarding your privacy and ensuring that your personal information is protected.
          </p>

          <h3>Information We Collect</h3>
          <h4>Personal Information</h4>
          <p>
            When you register on PullBill, we may collect the following personal information:
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Payment information (if applicable)</li>
              <li>Profile information (such as profile picture, preferences, etc.)</li>
            </ul>
          </p>

          <h4>Usage Data</h4>
          <p>
            We may also collect information on how you access and use PullBill, including:
            <ul>
              <li>Device information (e.g., IP address, browser type, device type, operating system)</li>
              <li>Log data (e.g., access times, pages viewed, and the duration of visits)</li>
              <li>Location information (if you allow location services)</li>
            </ul>
          </p>

          <h4>Transaction Data</h4>
          <p>
            We collect information related to your transactions and receipts, including:
            <ul>
              <li>Purchase details (e.g., items bought, purchase amount, store details)</li>
              <li>Receipt information (e.g., digital copies of receipts, transaction timestamps)</li>
            </ul>
            We donot sell personal or anytype of customer data to third parties or any other entites at anycost. we provide atmost importance to your data.
          </p>

          <h3>How We Use Your Information</h3>
          <p>
            We use the information we collect for the following purposes:
            <ul>
              <li><strong>Providing Services:</strong> To operate and maintain PullBill, including storing and managing your digital receipts.</li>
              <li><strong>Improving Services:</strong> To enhance, personalize, and expand our services based on your usage and feedback.</li>
              <li><strong>Communications:</strong> To communicate with you, including sending notifications, updates, and promotional materials.</li>
              <li><strong>Security:</strong> To protect the security and integrity of our services and your information.</li>
              <li><strong>Compliance:</strong> To comply with legal obligations and enforce our terms and policies.</li>
            </ul>
          </p>

          <h3>Sharing Your Information</h3>
          <p>
            We do not share your personal information with third parties except in the following circumstances:
            <ul>
              <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our services, subject to strict data protection agreements.</li>
              <li><strong>Legal Requirements:</strong> We may disclose your information if required by law, regulation, or legal process, or to protect the rights, property, or safety of PullBill, our users, or others.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</li>
            </ul>
          </p>

          <h3>Data Security</h3>
          <p>
            We implement appropriate technical and organizational measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no security measures are completely secure, and we cannot guarantee the absolute security of your information.
          </p>

          <h3>Your Rights</h3>
          <p>
            You have the following rights regarding your personal information:
            <ul>
              <li><strong>Access:</strong> You can request access to your personal information and obtain a copy.</li>
              <li><strong>Correction:</strong> You can request corrections to any inaccurate or incomplete information.</li>
              <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain legal obligations.</li>
              <li><strong>Withdrawal of Consent:</strong> You can withdraw your consent to our processing of your personal information at any time.</li>
            </ul>
          </p>

          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website and updating the effective date.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
          </p>
          <p>
            Email: [pullbillapp@gmail.com]<br />
          </p>
          <p>
            By using PullBill, you agree to the terms of this Privacy Policy. If you do not agree with this policy, please do not use our services.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
