import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import appStoreButton from '../assets/appStore.png'; // Add your App Store button image here
import playStoreButton from '../assets/playStore.png'; // Add your Play Store button image here

const MerchantInfo = () => {
  return (
    <div className="py-5">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2>PullBill and PullBill Merchant</h2>
            <p>Manage your transactions efficiently with PullBill and PullBill Merchant apps</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h3 className="text-center mt-4">PullBill App</h3>
            <div className="text-center">
              <Button variant="link" href="https://apps.apple.com/us/app/pullbill/id6467827535" className="m-2">
                <img src={appStoreButton} alt="App Store" style={{ width: '150px' }} />
              </Button>
              <Button variant="link" href="https://play.google.com/store/apps/details?id=com.harshith01.pb" className="m-2">
                <img src={playStoreButton} alt="Google Play" style={{ width: '150px' }} />
              </Button>
            </div>
            {/* <div className="text-center mt-4">
              <h4>App Screenshots</h4>
              <div className="d-flex justify-content-center">
                <img src="https://via.placeholder.com/200" alt="Screenshot 1" className="m-2" />
                <img src="https://via.placeholder.com/200" alt="Screenshot 2" className="m-2" />
                <img src="https://via.placeholder.com/200" alt="Screenshot 3" className="m-2" />
              </div>
            </div> */}
          </Col>
          <Col md={6}>
          <h3 className="text-center mt-4">PullBill Merchant App</h3>
            <div className="text-center">
              <Button variant="link" href="https://apps.apple.com/us/app/pullbill-merchant/id6502247380" className="m-2">
                <img src={appStoreButton} alt="App Store" style={{ width: '150px' }} />
              </Button>
              <Button variant="link" href="https://play.google.com/store/apps/details?id=com.pbmerchant.pb" className="m-2">
                <img src={playStoreButton} alt="Google Play" style={{ width: '150px' }} />
              </Button>
            </div>
            {/* <div className="text-center mt-4">
              <h4>App Screenshots</h4>
              <div className="d-flex justify-content-center">
                <img src="https://via.placeholder.com/200" alt="Screenshot 1" className="m-2" />
                <img src="https://via.placeholder.com/200" alt="Screenshot 2" className="m-2" />
                <img src="https://via.placeholder.com/200" alt="Screenshot 3" className="m-2" />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MerchantInfo;
