import React from 'react';
import './ReceiptTemplate1.css';

const ReceiptTemplate1 = ({ receiptData }) => {
  const { items, subtotal, total, currency, tax, adjustments, userData, customizationData, addressData, uniqueTaxRate } = receiptData;
  const hasTaxableItems = items.some(item => item.enableItemTax);

  const renderItemsTable = () => items.map((item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td>{item.quantity} {item.measurementUnit}</td>
      <td>{currency.symbol}{item.unitPrice.toFixed(2)}</td>
      {hasTaxableItems ? <td>{item.itemTax}%</td> : null}
      <td className="text-end">{currency.symbol}{(item.quantity * item.unitPrice).toFixed(2)}</td>
    </tr>
  ));

  const timestampToDate = (timestamp) => {
    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (timestamp && timestamp.seconds) {
      date = new Date(timestamp.seconds * 1000);
    } else {
      return 'Invalid Date';
    }
    return date.toLocaleDateString();
  };

  return (
    <div className="template-page">
      <div id="receipt" className="invoice-wrapper">
        <div className="invoice">
          <div className="invoice-container">
            <div className="invoice-head">
              <div className="invoice-head-top">
                <div className="invoice-head-top-left text-start">
                  {customizationData?.displayMerchantLogo && customizationData?.userImg && (
                    <img src={customizationData.userImg} alt="Merchant Logo" style={{ maxWidth: '100%', maxHeight: '60px' }} />
                  )}
                </div>
                <div className="invoice-head-top-right text-end">
                  <h3>Receipt</h3>
                </div>
                <hr />
              <div class="row">
                  <div class="col-sm-6"><strong>Date:</strong> {timestampToDate(receiptData.timestamp)}</div>
              </div>
              <hr />
              </div>
              <div className="hr"></div>
              <div className="invoice-head-bottom">
                <div className="invoice-head-bottom-left">
                  <div>{customizationData?.merchantname}</div>
                  {customizationData?.displayMerchantEmail && <div><b>Email:</b> {customizationData.email}</div>}
                  {customizationData?.displayMerchantPhone && <div><b>Phone:</b> {customizationData.phone.phoneNumber}</div>}
                  {customizationData?.displayMerchantAddress && addressData && (
                    <>
                      <div><b>Address:</b> {addressData.line1}, {addressData.line2}</div>
                      <div>{addressData.city}, {addressData.state}, {addressData.country}, {addressData.zipcode}</div>
                    </>
                  )}
                </div>
                <div className="invoice-head-bottom-right text-end">
                  <div className="text-bold">Bill To:</div>
                  <div>{userData?.fname} {userData?.lname}</div>
                  {userData?.email && <div>{userData.email}</div>}
                  {userData?.phone?.countryCode && userData?.phone?.phoneNumber && <div>{userData.phone.countryCode} {userData.phone.phoneNumber}</div>}
                </div>
              </div>
            </div>
            <div className="overflow-view">
              <div className="invoice-body">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>QTY</th>
                      <th>Rate</th>
                      {hasTaxableItems && <th>Tax</th>}
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderItemsTable()}
                  </tbody>
                </table>
                <div className='padding-left'>
                <div className="invoice-body-bottom">
                  <div className="invoice-body-info-item border-bottom">
                    <div className="info-item-td text-end text-bold">Sub Total:</div>
                    <div className="info-item-td text-end">{currency.symbol}{subtotal}</div>
                  </div>
                  {adjustments.length > 0 && (
                    adjustments.map((adjustment, index) => (
                      <div key={index} className="invoice-body-info-item border-bottom">
                        <div className="info-item-td text-end text-bold">{adjustment.adjustmentName}{adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</div>
                        <div className="info-item-td text-end">{currency.symbol}{adjustment.adjustmentValue}</div>
                      </div>
                    ))
                  )}
                  <div className="invoice-body-info-item border-bottom">
                    <div className="info-item-td text-end text-bold">Tax{uniqueTaxRate !== null ? ` (${uniqueTaxRate}%)` : ''}:</div>
                    <div className="info-item-td text-end">{currency.symbol}{tax}</div>
                  </div>
                  <div className="invoice-body-info-item">
                    <div className="info-item-td text-end text-bold">Total:</div>
                    <div className="info-item-td text-end">{currency.symbol}{total}</div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div className="invoice-foot text-start">
              {customizationData?.Terms && (
                <p>
                  <strong>Terms:&nbsp;</strong>
                  {customizationData.Terms}
                </p>
              )}
              {customizationData?.returnPolicy && (
                <p>
                  <strong>Return policy:&nbsp;</strong>
                  {customizationData.returnPolicy}
                </p>
              )}
              <p>
                <strong>NOTE:&nbsp;</strong>
                This is a computer-generated receipt and does not require a physical signature.
              </p>
            </div>

          </div>
        </div>
      </div>
      {/* <ReceiptActions receiptData={receiptData} /> */}
    </div>
  );
};

export default ReceiptTemplate1;
