import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CountUp from 'react-countup';

const CountersSection = () => {
  return (
    <div className="py-5 bg-light">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2>Our Impact</h2>
            <p>Discover the amazing impact PullBill is making</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Merchants Joined</Card.Title>
                <h3>
                  <CountUp start={0} end={100} duration={5} separator="," />+
                </h3>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Digital Receipts Sent</Card.Title>
                <h3>
                  <CountUp start={0} end={2000} duration={5} separator="," />+
                </h3>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Receipts stored:</Card.Title>
                <h3>
                  <CountUp start={0} end={4000} duration={5} separator="," />+
                </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CountersSection;
