import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChartLine, FaRegFileAlt, FaQrcode, FaRobot,FaStore, FaRegListAlt, FaMobileAlt, FaClipboardList, FaRegCheckCircle, FaUserFriends, FaReceipt } from 'react-icons/fa';

const FeaturesSection = () => {
  return (
    <div className="bg-light py-5" id="features">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2>Key Features</h2>
            <p>Discover the amazing features of PullBill</p>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaChartLine size={50} className="mb-3" />
                <Card.Title>Expense Tracking and Dashboard</Card.Title>
                <Card.Text>
                  Record all your expenses and visualize them through an intuitive dashboard, helping in budget management.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaRegFileAlt size={50} className="mb-3" />
                <Card.Title>Digital Receipts Storage</Card.Title>
                <Card.Text>
                  Upload and store receipts digitally for lifetime access, ensuring you never lose track of any transaction.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaClipboardList size={50} className="mb-3" />
                <Card.Title>Easy Filtering and Categorization</Card.Title>
                <Card.Text>
                  Easily filter and categorize receipts, making it simple to find specific transactions and track expenses by category.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        <Row>
          <Col md={4}>
            <Card className="mb-4">
            <Card.Body>
                <FaQrcode size={50} className="mb-3" />
                <Card.Title>Seamless Receipt Sending using QR Code</Card.Title>
                <Card.Text>
                  Send digital receipts to customers through QR codes, contact picker, or a 5-digit code.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
            <Card.Body>
                <FaUserFriends size={50} className="mb-3" />
                <Card.Title>Split Transactions and Settle Up</Card.Title>
                <Card.Text>
                  In future updates, split transactions between users and settle up with ease.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaRegListAlt size={50} className="mb-3" />
                <Card.Title>Customizable Receipt Templates</Card.Title>
                <Card.Text>
                  Create professional, customized receipts using a variety of templates to meet your business needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaMobileAlt size={50} className="mb-3" />
                <Card.Title>Super Fast Access</Card.Title>
                <Card.Text>
                  PullBill provides a super fast and responsive experience, allowing users to access their data quickly and efficiently.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
            <Card.Body>
                <FaReceipt size={50} className="mb-3" />
                <Card.Title>Receipt Hub</Card.Title>
                <Card.Text>
                  Centralize all receipts, ensuring customers receive their receipts regardless of where the purchase was made.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaRobot size={50} className="mb-3" />
                <Card.Title>AI Bot Assistance</Card.Title>
                <Card.Text>
                  In future updates, use our AI bot to answer questions about your expenses and provide quick support.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaChartLine size={50} className="mb-3" />
                <Card.Title>AI Analysis</Card.Title>
                <Card.Text>
                  In future updates, leverage AI to analyze your spending patterns and provide insightful recommendations.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaStore size={50} className="mb-3" />
                <Card.Title>Easy Integration</Card.Title>
                <Card.Text>
                  Integrate PullBill into your existing POS systems with ease. We also support wordpress, API integrations and other major platforms.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <FaRegCheckCircle size={50} className="mb-3" />
                <Card.Title>Push Notifications</Card.Title>
                <Card.Text>
                  Send push notifications to customers about their receipts and updates. Engage customers efficiently. -pullbill
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesSection;
