import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ReceiptTemplate3 = ({ receiptData }) => {
  const { receiptId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [data, setData] = useState(receiptData || null);

    useEffect(() => {
      if (!receiptData) {
        const fetchReceipt = async () => {
          try {
            const docRef = doc(db, 'expenses', receiptId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const receiptData = docSnap.data();
              setData(receiptData);
              setHtmlContent(generateHtmlContent(receiptData));
            } else {
              console.error('No such document!');
            }
          } catch (error) {
            console.error('Error fetching receipt', error);
          }
        };
        fetchReceipt();
      } else {
        setHtmlContent(generateHtmlContent(receiptData));
      }
    }, [receiptId, receiptData]);
  
    if (!data) {
      return <div>Loading...</div>;
    }
  
  const generateHtmlContent = (receiptData) => {
    const {
      items, subtotal, total, currency, tax, adjustments, customizationData, addressData, uniqueTaxRate, timestamp
    } = receiptData;

    const hasTaxableItems = items.some((item) => item.enableItemTax);
    // const colspanValue = hasTaxableItems ? 4 : 3;

    const renderItemsTable = items.map((item, index) => (
      `<tr key=${index}>
          <td>${item.name}</td>
          <td>${item.quantity} ${item.measurementUnit}</td>
          <td>${currency.symbol}${item.unitPrice.toFixed(2)}</td>
          ${hasTaxableItems ? `<td>${item.itemTax}%</td>` : ''}
          <td class="text-end">${currency.symbol}${(item.quantity * item.unitPrice).toFixed(2)}</td>
      </tr>`
    )).join('');

    // const renderAdjustments = adjustments.map((adjustment, index) => (
    //   `<tr key=${index}>
    //       <td colSpan=${colspanValue} class="text-end border-bottom-0">
    //           <strong>${adjustment.adjustmentName}${adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</strong>
    //       </td>
    //       <td class="text-end border-bottom-0">
    //           ${currency.symbol}${adjustment.adjustmentValue}
    //       </td>
    //   </tr>`
    // )).join('');

    const timestampToDate = (timestamp) => {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp && timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return 'Invalid Date';
      }
      return date.toLocaleDateString();
    };

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Receipt</title>
          <link rel="stylesheet" type="text/css" href="https://harshithvemulas.github.io/pbcss/main-style.css">
      </head>
      <body>
      <main class="container receipt-wrapper" id="download-section">
          <div class="receipt-top">
              <div class="company-name">${customizationData?.merchantname}</div>
              ${customizationData?.displayMerchantAddress && addressData ? `
              <div class="company-address">${addressData.line1}, ${addressData.line2}</div>
              <div>${addressData.city}, ${addressData.state}, ${addressData.country}, ${addressData.zipcode}</div>` : ''}
              ${customizationData?.displayMerchantEmail && customizationData?.email ? `<div class="company-email">Email: ${customizationData.email}</div>` : ''}
              ${customizationData?.displayMerchantPhone && customizationData?.phone?.phoneNumber ? `<div class="company-phone">Phone: ${customizationData.phone.phoneNumber}</div>` : ''}
          </div>
          <div class="receipt-body">
              <div class="receipt-heading"><span>cash memo</span></div>
              <ul class="text-list text-style1">
                  <li>
                      <div class="text-list-title">Date:</div>
                      <div class="text-list-desc">${timestampToDate(timestamp)}</div>
                  </li>
              </ul>
              <table class="receipt-table">
                  <thead>
                      <tr>
                          <th>Name</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          ${hasTaxableItems ? `<th class="col-2 text-center">Tax</th>` : ''}
                          <th>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${renderItemsTable}
                  </tbody>
              </table>
              <div class="text-bill-list mb-15">
                  <div class="text-bill-list-in">
                      <div class="text-bill-title">Sub-Total:</div>
                      <div class="text-bill-value">${currency.symbol}${subtotal}</div>
                  </div>
                  ${adjustments.map((adjustment, index) => `
                      <div class="text-bill-list-in" key=${index}>
                          <div class="text-bill-title">${adjustment.adjustmentName}${adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</div>
                          <div class="text-bill-value">${currency.symbol}${adjustment.adjustmentValue}</div>
                      </div>`).join('')}
                  <div class="text-receipt-seperator"></div>
                  <div class="text-bill-list-in">
                      <div class="text-bill-title">Tax${uniqueTaxRate !== null ? ` (${uniqueTaxRate}%)` : ''}:</div>
                      <div class="text-bill-value">${currency.symbol}${tax}</div>
                  </div>
                  <div class="text-receipt-seperator"></div>
                  <div class="text-bill-list-in">
                      <div class="text-bill-title">Total Bill:</div>
                      <div class="text-bill-value">${currency.symbol}${total}</div>
                  </div>
              </div>
              ${customizationData?.returnPolicy ? `
                  <div class="mb-10">
                      <h4 class="mb-2 text-title font-700 text-border">Return Policy:</h4>
                      <p>${customizationData.returnPolicy}</p>
                  </div>` : ''}
              ${customizationData?.Terms ? `
                  <div class="mb-10">
                      <h4 class="mb-2 text-title font-700 text-border">Terms and Conditions:</h4>
                      <p>${customizationData.Terms}</p>
                  </div>` : ''}
              <div class="text-center">
                  <h4 class="mb-0 text-title font-500">*** Thank you for shopping with us ***</h4>
              </div>
          </div>
      </main>
      </body>
      </html>`;
  };


  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};


export default ReceiptTemplate3;
