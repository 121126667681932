import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import './Dashboard.css';
import SquareIntegration from '../components/square/SquareIntegration';


const Dashboard = () => {
  const [apiKeys, setApiKeys] = useState({ dev: '', prod: '' });
  const [loading, setLoading] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [showProdKey, setShowProdKey] = useState(false);
  const [merchantUuid, setMerchantUuid] = useState('');
  const [connectedToSquare, setConnectedToSquare] = useState(false); // NEW

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setMerchantUuid(user.uid);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCustomerName(userData.fname + ' ' + (userData.lname || 'Customer'));
          setApiKeys(userData.apiKeys || { dev: '', prod: '' });
          setConnectedToSquare(userData.connectedToSquare || false); 
        }
      } else {
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    if (location.state?.refetch) {
      const fetchUserData = async () => {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCustomerName(userData.fname + ' ' + (userData.lname || 'Customer'));
            setApiKeys(userData.apiKeys || { dev: '', prod: '' });
            setConnectedToSquare(userData.connectedToSquare || false); 

          }
        }
      };

      fetchUserData();
    }
  }, [location]);


  const handleDisconnectSquare = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken();
  
      const response = await fetch('https://pullbill.com/api/disconnect-square', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ merchantUuid }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      alert('Square account disconnected successfully.');
      setConnectedToSquare(false); // Update the state to reflect disconnection
    } catch (error) {
      console.error('Error disconnecting Square:', error.message);
      alert('Failed to disconnect Square. Please try again.');
    }
  };
  

  const recreateApiKey = async (env) => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User is not authenticated.');
      }

      const idToken = await user.getIdToken();
      const response = await fetch('https://pullbill.com/api/recreateApiKey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ uid: user.uid, env }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }

      const data = await response.json();
      setApiKeys((prev) => ({ ...prev, [env]: data[env] }));
      alert(`${env.toUpperCase()} API Key recreated.`);
    } catch (error) {
      console.error(`Error recreating ${env} API key:`, error);
      alert(`Failed to recreate ${env} API key: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <p>Hello, {customerName}</p>
      <div className="api-keys">
        <div className="api-key">
          <h3>Prod API Key</h3>
          <input 
            type={showProdKey ? 'text' : 'password'} 
            value={apiKeys.prod} 
            readOnly 
          />
          <button onClick={() => setShowProdKey(!showProdKey)}>
            {showProdKey ? 'Hide' : 'Show'}
          </button>
          <button onClick={() => recreateApiKey('prod')} disabled={loading}>
            {loading ? 'Recreating...' : 'Recreate Prod API Key'}
          </button>
        </div>
      </div>

      <div className="integration-section">
        {connectedToSquare ? (
          <div className="connected-message">
            <p style={{ color: 'green', fontWeight: 'bold' }}>You are connected to Square!</p>
            <button
              onClick={handleDisconnectSquare}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                backgroundColor: 'red',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Disconnect from Square
            </button>
          </div>
        ) : (
          <SquareIntegration merchantUuid={merchantUuid} />
        )}
      </div>

    </div>

  );
};

export default Dashboard;
