import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import bgImage from '../assets/bgimager.png';

const AboutPullBill = () => {
    return (
      <div className="py-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h2>About PullBill</h2>
              <p>
              Welcome to PullBill, the ultimate digital receipt management system designed to simplify and secure your purchase tracking. Say goodbye to the hassle of lost paper receipts and manual data entry. With PullBill, your receipts are automatically saved to your account through seamless integration with leading retailers and payment systems. Store, manage, and access your receipts conveniently from anywhere, at any time. In future updates, our AI analysis will help you gain deeper insights into your spending habits, allowing you to manage your finances more effectively. Our merchant app further enhances the experience by providing businesses with tools to send digital receipts to customers instantly and manage their transaction records efficiently. Integrate PullBill into your existing POS systems with ease. We also support WordPress, APIs, and other major platforms, enabling you to get receipts from merchants in any format.
              </p>
              <Button variant="primary" href="#features" className="mt-3">Explore Features</Button>
            </Col>
            <Col md={6}>
              <img src={bgImage} alt="About PullBill" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  

export default AboutPullBill;
