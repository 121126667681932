import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCEghPtbgqdTqjV9ImRxTas0JiR-F5nhrk",
    authDomain: "pullbill-merchant.firebaseapp.com",
    projectId: "pullbill-merchant",
    storageBucket: "pullbill-merchant.appspot.com",
    messagingSenderId: "583039763107",
    appId: "1:583039763107:web:4ac36a4c689d8c7e643516",
    measurementId: "G-YMH88K3M2B"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
auth.useDeviceLanguage();
const googleProvider = new GoogleAuthProvider();

export { db, auth, googleProvider, signInWithPopup, signInWithPhoneNumber, RecaptchaVerifier };
