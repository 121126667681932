import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ReceiptTemplate5 = ({ receiptData }) => {
  const { receiptId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [data, setData] = useState(receiptData || null);

  useEffect(() => {
    if (!receiptData) {
      const fetchReceipt = async () => {
        try {
          const docRef = doc(db, 'expenses', receiptId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const receiptData = docSnap.data();
            setData(receiptData);
            setHtmlContent(generateHtmlContent(receiptData));
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching receipt', error);
        }
      };
      fetchReceipt();
    } else {
      setHtmlContent(generateHtmlContent(receiptData));
    }
  }, [receiptId, receiptData]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const generateHtmlContent = (receiptData) => {
    const {
      items, subtotal, total, currency, tax, adjustments,
      userData, customizationData, addressData, uniqueTaxRate, timestamp
    } = receiptData;

    const hasTaxableItems = items.some((item) => item.enableItemTax);
    const colspanValue = hasTaxableItems ? 4 : 3;

    const renderItemsTable = items.map((item, index) => (
      `<tr key=${index}>
          <td>${item.name}</td>
          <td>${item.quantity} ${item.measurementUnit}</td>
          <td>${currency.symbol}${item.unitPrice.toFixed(2)}</td>
          ${hasTaxableItems ? `<td>${item.itemTax}%</td>` : ''}
          <td class="text-end">${currency.symbol}${(item.quantity * item.unitPrice).toFixed(2)}</td>
      </tr>`
    )).join('');

    const renderAdjustments = adjustments.map((adjustment, index) => (
      `<tr key=${index}>
          <td colSpan=${colspanValue} class="text-end border-bottom-0">
              <strong>${adjustment.adjustmentName}${adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</strong>
          </td>
          <td class="text-end border-bottom-0">
              ${currency.symbol}${adjustment.adjustmentValue}
          </td>
      </tr>`
    )).join('');

    const timestampToDate = (timestamp) => {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp && timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return 'Invalid Date';
      }
      return date.toLocaleDateString();
    };

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Receipt</title>
          <link rel="stylesheet" type="text/css" href="https://harshithvemulas.github.io/pbcss/main-style.css">
          <style>
            /* Additional CSS styles */
            .invoice-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
            }

            .invoice-top img {
              max-width: 100%;
              max-height: 50px;
            }

            .invoice-title {
              font-size: 35px;
              text-transform: uppercase;
            }

            .address-section {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-wrap: wrap;
            }

            .address-section .address-column {
              flex: 0 0 48%;
              margin-bottom: 20px;
            }

            .table-responsive {
              overflow-x: auto;
            }

            .table {
              width: 100%;
            }

            @media only screen and (max-width: 768px) {
              .table thead {
                display: none;
              }

              .table tbody {
                display: block;
              }

              .table tbody tr {
                display: block;
                border-bottom: 1px solid #ddd;
                margin-bottom: 10px;
              }

              .table tbody td {
                display: block;
                text-align: left;
              }
            }

            .extra-padding {
              min-height: 180px;
            }

            * { 
              print-color-adjust: exact !important; 
            }
          </style>
          <script>
            document.addEventListener("DOMContentLoaded", function() {
              const numItems = document.querySelectorAll('.table.table-striped tbody tr').length;
              const container = document.querySelector('.extra-padding');

              if (numItems === 1) {
                container.style.minHeight = '360px';
                container.style.paddingBottom = '50px';
              } else if (numItems === 2) {
                container.style.minHeight = '345px';
                container.style.paddingBottom = '45px';
              } else if (numItems === 3) {
                container.style.minHeight = '350px';
                container.style.paddingBottom = '40px';
              } else {
                container.style.minHeight = '210px';
                container.style.paddingBottom = '20px';
              }
            });
          </script>
      </head>
      <body class="section-bg-one">
        <main class="position-relative">
          <div class="modern-invoice3" id="download-section">
            <!-- invoice Top -->
            <div class="invoice-top">
              <div>
                <!-- Logo -->
                ${customizationData?.displayMerchantLogo && customizationData?.userImg ? 
                `<a href="index.html"><img src="${customizationData.userImg}" alt="Test Logo"></a>` : ''} 
              </div>
              <div>
                <!-- Invoice Title -->
                <h4 class="text-30">Receipt</h4>
              </div>
            </div>
            <!-- invoice Details -->
            <div class="invoice-details pt-20">
              <div class="row address-section">
                <div class="col-sm-6 text-start order-sm-0 address-column">
                  <strong class="text-18 mb-3 d-inline-block">Bill To:</strong>
                  <address class="mb-4">
                    ${userData?.fname} ${userData?.lname}<br>
                    ${userData?.email ? `Email: ${userData.email}<br>` : ''}
                    ${userData?.phone?.countryCode} ${userData?.phone?.phoneNumber ? `Phone: ${userData.phone.countryCode} ${userData.phone.phoneNumber}<br>` : ''}
                  </address>
                </div>
                <div class="col-sm-6 order-sm-1 address-column">
                  <strong class="text-18 mb-3 d-inline-block">Pay To:</strong>
                  <address class="mb-4">
                    ${customizationData?.merchantname}<br>
                    ${customizationData?.displayMerchantEmail && customizationData?.email ? `Email: ${customizationData.email}<br>` : ''}
                    ${customizationData?.displayMerchantPhone && customizationData?.phone?.phoneNumber ? `Phone: ${customizationData.phone.phoneNumber}<br>` : ''}
                    ${customizationData?.displayMerchantAddress && addressData ? `
                      ${addressData.line1}, ${addressData.line2}<br>
                      ${addressData.city}, ${addressData.state}, ${addressData.country}<br>
                      ${addressData.zipcode}<br>` : ''}
                  </address>
                </div>
              </div>
              <!-- invoice Table -->
              <div class="table-responsive invoice-table mb-4">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-white">Name</th>
                      <th class="text-white">Qty</th>
                      <th class="black-bg text-white">Rate</th>
                      ${hasTaxableItems ? `<th class="black-bg text-white">Tax</th>` : ''}
                      <th class="black-bg text-end text-white">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${renderItemsTable}
                  </tbody>
                  <tfoot>
                    <tr class="card-footer">
                      <td colspan="${colspanValue}" class="text-end"><strong>Sub Total:</strong></td>
                      <td class="text-end">${currency.symbol}${subtotal}</td>
                    </tr>
                    ${renderAdjustments}
                    <tr>
                      <td colspan="${colspanValue}" class="text-end"><strong>Tax${uniqueTaxRate !== null ? ` (${uniqueTaxRate}%)` : ''}:</strong></td>
                      <td class="text-end">${currency.symbol}${tax}</td>
                    </tr>
                    <tr>
                      <td colspan="${colspanValue}" class="text-end border-bottom-0"><strong>Total:</strong></td>
                      <td class="text-end border-bottom-0">${currency.symbol}${total}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <!-- invoice address -->
              <div class="row">
                <div class="col-sm-12 mb-20">
                  <span class="status d-block"> <strong>Date :</strong> ${timestampToDate(timestamp)}</span>
                  <div class="extra-padding"></div>
                  <h5 class="mb-2 text-title font-700"> Important: </h5>
                  ${customizationData?.Terms ? `<p>${customizationData.Terms}</p>` : ''}
                  ${customizationData?.returnPolicy ? `<p>${customizationData.returnPolicy}</p>` : ''}
                  <p>This is an electronic generated invoice so doesn't require any signature.</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </body>
      </html>`;
  };

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default ReceiptTemplate5;
