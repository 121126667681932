import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './ReceiptTemplate2.css';

const ReceiptTemplate2 = ({ receiptData }) => {
  const { receiptId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [data, setData] = useState(receiptData || null);

  useEffect(() => {
    if (!receiptData) {
      const fetchReceipt = async () => {
        try {
          const docRef = doc(db, 'expenses', receiptId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const receiptData = docSnap.data();
            setData(receiptData);
            setHtmlContent(generateHtmlContent(receiptData));
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching receipt', error);
        }
      };
      fetchReceipt();
    } else {
      setHtmlContent(generateHtmlContent(receiptData));
    }
  }, [receiptId, receiptData]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const generateHtmlContent = (receiptData) => {
    const {
      items, subtotal, total, currency, tax, adjustments,
      userData, customizationData, addressData, uniqueTaxRate, timestamp
    } = receiptData;

    const hasTaxableItems = items.some((item) => item.enableItemTax);
    const colspanValue = hasTaxableItems ? 4 : 3;

    const renderItemsTable = items.map((item, index) => (
      `<tr key=${index}>
          <td>${item.name}</td>
          <td>${item.quantity} ${item.measurementUnit}</td>
          <td>${currency.symbol}${item.unitPrice.toFixed(2)}</td>
          ${hasTaxableItems ? `<td>${item.itemTax}%</td>` : ''}
          <td class="text-end">${currency.symbol}${(item.quantity * item.unitPrice).toFixed(2)}</td>
      </tr>`
    )).join('');

    const renderAdjustments = adjustments.map((adjustment, index) => (
      `<tr key=${index}>
          <td colSpan=${colspanValue} class="text-end border-bottom-0">
              <strong>${adjustment.adjustmentName}${adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</strong>
          </td>
          <td class="text-end border-bottom-0">
              ${currency.symbol}${adjustment.adjustmentValue}
          </td>
      </tr>`
    )).join('');

    const timestampToDate = (timestamp) => {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp && timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return 'Invalid Date';
      }
      return date.toLocaleDateString();
    };

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Receipt</title>
          <link rel="stylesheet" type="text/css" href="https://harshithvemulas.github.io/pbcss/main-style.css">
      </head>
      <body>
      <main class="container invoice-wrapper" id="download-section">
          <div class="invoice-top">
              <div class="row align-items-center">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start mb-3 mb-sm-1">
                      ${customizationData?.displayMerchantLogo && customizationData?.userImg ? 
                          `<a href="index.html"><img src="${customizationData.userImg}" title="invoice" alt="invoice"></a>` : ''
                      }
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-end mb-3 mb-sm-1">
                      <h4 class="text-30 mb-0 mt-0">Receipt</h4>
                      <p><strong>Date:</strong> ${timestampToDate(timestamp)}</p>
                  </div>
              </div>
              <hr>
          </div>
          <div class="invoice-details border-top mt-20 pt-20">
              <div class="row">
                  <div class="col-sm-6 text-sm-start">
                      <strong class="text-18 mb-3 d-inline-block">Bill To:</strong>
                      <address class="mb-4">
                          ${userData.fname} ${userData.lname}<br>
                          ${userData.email}<br>
                          ${userData.phone.countryCode} ${userData.phone.phoneNumber}
                      </address>
                  </div>
                  <div class="col-sm-6 text-sm-end">
                      <strong class="text-18 mb-3 d-inline-block">Pay To:</strong>
                      <address class="mb-4">
                          ${customizationData?.merchantname}<br>
                          ${customizationData?.displayMerchantEmail && customizationData?.email ? `Email: ${customizationData.email}<br>` : ''}
                          ${customizationData?.displayMerchantPhone && customizationData?.phone?.phoneNumber ? `Phone: ${customizationData.phone.phoneNumber}<br>` : ''}
                          ${customizationData?.displayMerchantAddress && addressData ? `
                              ${addressData.line1}, ${addressData.line2}<br>
                              ${addressData.city}, ${addressData.state}, ${addressData.country}, ${addressData.zipcode}` : ''}
                      </address>
                  </div>
              </div>
          </div>
          <div class="card mb-3">
              <div class="card-body p-0">
                  <div class="table-responsive">
                      <table class="table mb-0">
                          <thead class="card-header">
                              <tr>
                                  <th>Name</th>
                                  <th>QTY</th>
                                  <th>Rate</th>
                                  ${hasTaxableItems ? '<th>Tax</th>' : ''}
                                  <th class="text-end">Amount</th>
                              </tr>
                          </thead>
                          <tbody>
                              ${renderItemsTable}
                          </tbody>
                          <tfoot>
                              <tr>
                                  <td colSpan="${colspanValue}" class="text-end"><strong>Sub Total:</strong></td>
                                  <td class="text-end">${currency.symbol}${subtotal}</td>
                              </tr>
                              ${renderAdjustments}
                              <tr>
                                  <td colSpan="${colspanValue}" class="text-end"><strong>Tax${uniqueTaxRate !== null ? ` (${uniqueTaxRate}%)` : ''}:</strong></td>
                                  <td class="text-end">${currency.symbol}${tax}</td>
                              </tr>
                              <tr>
                                  <td colSpan="${colspanValue}" class="text-end"><strong>Total:</strong></td>
                                  <td class="text-end">${currency.symbol}${total}</td>
                              </tr>
                          </tfoot>
                      </table>
                  </div>
              </div>
          </div>
          ${customizationData?.returnPolicy ? `
              <div class="mb-20">
                  <h4 class="mb-2 text-title font-700 text-border">Return Policy:</h4>
                  <p>${customizationData.returnPolicy}</p>
              </div>` : ''
          }
          ${customizationData?.Terms ? `
              <div class="mb-10">
                  <h4 class="mb-2 text-title font-700 text-border">Terms and Conditions:</h4>
                  <p>${customizationData.Terms}</p>
              </div>` : ''
          }
          <div class="text-center">
              <h4 class="mb-0 text-title font-500">*** Thank you for shopping with us ***</h4>
          </div>
      </main>
      </body>
      </html>`;
  };

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default ReceiptTemplate2;
