import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfService = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <h2>1. Introduction</h2>
          <p>
            Welcome to PullBill. By accessing or using our services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
          </p>

          <h2>2. Use of Services</h2>
          <p>
            You agree to use our services only for lawful purposes and in accordance with these Terms of Service. You must not use our services:
            <ul>
              <li>In any way that breaches any applicable local, national, or international law or regulation.</li>
              <li>For the purpose of harming or attempting to harm minors in any way.</li>
              <li>To send, knowingly receive, upload, download, use, or re-use any material which does not comply with our content standards.</li>
              <li>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
              <li>We are not responsible for any issue in the calculations or any mistake that may occur in the receipts.  </li>
          </ul>
          </p>

          <h2>3. Account Security</h2>
          <p>
            You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.
          </p>

          <h2>4. Intellectual Property Rights</h2>
          <p>
            All intellectual property rights in the services and the material published on it are owned by us or our licensors. These works are protected by copyright laws and treaties around the world. All such rights are reserved.
          </p>

          <h2>5. User Content</h2>
          <p>
            You retain ownership of any intellectual property rights that you hold in the content you submit. When you upload or otherwise submit content to our services, you give PullBill (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations, or other changes we make so that your content works better with our services), communicate, publish, publicly perform, publicly display, and distribute such content.
          </p>

          <h2>6. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by applicable law, PullBill shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
            <ul>
              <li>Your use of or inability to use our services;</li>
              <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
              <li>Any bugs, viruses, trojan horses, or the like which may be transmitted to or through our services by any third party;</li>
              <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the services.</li>
            </ul>
          </p>

          <h2>7. Disclaimer of Warranties</h2>
          <p>
            Our services are provided "as is" and "as available" without any warranties of any kind, either express or implied. PullBill does not warrant that the services will be uninterrupted or error-free, that defects will be corrected, or that the services or the server that makes the services available are free of viruses or other harmful components. PullBill does not warrant or make any representations regarding the use or the results of the use of the services in terms of their correctness, accuracy, reliability, or otherwise.
          </p>

          <h2>8. Changes to the Terms</h2>
          <p>
            We may revise these Terms of Service at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you.
          </p>

          <h2>9. Governing Law</h2>
          <p>
            These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which PullBill operates, without regard to its conflict of law principles.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions about these Terms of Service, please contact us at pullbillapp@gmail.com.
          </p>

          <h2>11. No Responsibility for Mistakes</h2>
          <p>
            PullBill is not responsible for any mistakes in receipts, data inaccuracies, or calculation errors due to software bugs or other issues. Users are advised to review their receipts and transactions for accuracy.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfService;
