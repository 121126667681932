import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Receipt from '../components/Receipt';

const ReceiptTemplatePage = () => {
    const { id } = useParams();
    return <Receipt receiptId={id} />;
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
};

export default ReceiptTemplatePage;
