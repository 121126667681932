import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup, signInWithPhoneNumber, RecaptchaVerifier, db } from '../firebaseConfig';
import AppleSignin from 'react-apple-signin-auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './Login.css';
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import googleIcon from '../assets/google.png';
import appleIcon from '../assets/apple.png';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handlePhoneSignIn = useCallback(async () => {
    const phoneRegex = /^[0-9]{10,15}$/;
    const trimmedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    console.log('trimmedPhoneNumber', trimmedPhoneNumber);
    if (!trimmedPhoneNumber || !phoneRegex.test(trimmedPhoneNumber)) {
      alert('Please enter a valid phone number.');
      console.log('phoneNumber', trimmedPhoneNumber);
      return;
    }

    setLoading(true);
    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, `+${trimmedPhoneNumber}`, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setShowOtpInput(true);
    } catch (error) {
      console.error('Error during phone sign-in:', error);
      alert('Sign-in Error. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            // Recaptcha verified
          },
          'expired-callback': () => {
            alert('Recaptcha expired. Please try again.');
          },
        },
        auth
      );
    }
  }, []);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      const userExists = await checkUserExists(result.user.uid);

      if (!userExists) {
        alert('User does not exist in Pullbill Merchant. Please register first.');
        await auth.signOut();
        setLoading(false);
        return;
      }

      await generateAndStoreApiKey(result.user.uid, idToken);
      navigate('/dashboard', { state: { refetch: true } });
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      alert('Sign-in Error. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (!otp) {
      alert('Please enter the OTP.');
      return;
    }

    setLoading(true);
    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      const result = await signInWithCredential(auth, credential);
      const idToken = await result.user.getIdToken();
      const userExists = await checkUserExists(result.user.uid);

      if (!userExists) {
        alert('User does not exist in Pullbill Merchant. Please register first.');
        await auth.signOut();
        setShowOtpInput(false);
        setLoading(false);
        return;
      }

      await generateAndStoreApiKey(result.user.uid, idToken);
      navigate('/dashboard', { state: { refetch: true } });
    } catch (error) {
      console.error('Error during OTP verification:', error);
      console.error('Verification Error:', error.message);
      alert(`Verification Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAppleSignIn = async (response) => {
    setLoading(true);
    try {
      // Log the entire response object
      console.log('Apple Sign-In Response:', response);
  
      const idToken = response.authorization.id_token;
      
      // Decode the ID token to extract the 'sub' field
      const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
      const userId = decodedToken.sub;
  
      // Log the extracted idToken and userId
      console.log('ID Token:', idToken);
      console.log('User ID:', userId);
  
      if (!userId) {
        throw new Error('User ID not found in Apple Sign-In response');
      }
  
      const userExists = await checkUserExists(userId);
  
      // Log if the user exists in the database
      console.log('User exists in database:', userExists);
  
      if (!userExists) {
        alert('User does not exist in Pullbill Merchant. Please register first.');
        await auth.signOut();
        setLoading(false);
        return;
      }
  
      await generateAndStoreApiKey(userId, idToken);
      navigate('/dashboard', { state: { refetch: true } });
    } catch (error) {
      console.error('Error during Apple sign-in:', error);
      alert('Sign-in Error. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  const checkUserExists = async (uid) => {
    const userDoc = await getDoc(doc(db, 'users', uid));
    return userDoc.exists();
  };

  const generateAndStoreApiKey = async (uid, idToken) => {
    try {
      // Check if the user already has API keys
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists() && userDoc.data().apiKeys) {
        console.log('API Keys already exist for this user.');
        return;
      }

      // Generate new API keys if they do not exist
      const response = await fetch('https://pullbill.com/api/generateApiKey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ uid }),
      });

      const data = await response.json();
      if (data.apiKeys) {
        await setDoc(doc(db, 'users', uid), {
          apiKeys: data.apiKeys,
        }, { merge: true });
        alert('API Keys generated and stored.');
      } else {
        alert('Failed to generate API Keys');
      }
    } catch (error) {
      console.error('Error generating API Keys:', error);
      alert('Error generating API Keys. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login to Pullbill Merchant</h2>
      <button className="login-button" onClick={handleGoogleSignIn} disabled={loading}>
        {loading ? 'Signing in...' : (
          <>
            <img src={googleIcon} alt="Google Icon" className="login-icon" />
            Login with Google
          </>
        )}
      </button>
      <AppleSignin
        authOptions={{
          clientId: 'com.pbweb.signin', // Your Apple client ID
          scope: 'name email', // Requesting name and email scopes
          redirectURI: 'https://pullbill.com/dashboard', // Your redirect URI
          state: 'state', // Optional
          nonce: 'nonce', // Optional
          usePopup: true,
        }}
        onSuccess={handleAppleSignIn}
        onError={(error) => console.error('Apple Sign-In Error:', error)}
        render={(props) => (
          <button {...props} className="login-button" disabled={loading}>
            {loading ? 'Signing in...' : (
              <>
                <img src={appleIcon} alt="Apple Icon" className="login-icon" />
                Login with Apple
              </>
            )}
          </button>
        )}
      />
      <div id="recaptcha-container"></div>
      <div>
        <h3>Phone Number Login</h3>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value.replace(/\D/g, ''))} // Remove non-numeric characters
          inputStyle={{ width: '100%' }}
        />
        <button className="login-button" onClick={handlePhoneSignIn} disabled={loading || !phoneNumber}>
          {loading ? 'Sending OTP...' : 'Login with Phone'}
        </button>
      </div>
      {showOtpInput && (
        <div>
          <h3>Enter OTP</h3>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
          />
          <button className="login-button" onClick={verifyOtp} disabled={loading}>
            {loading ? 'Verifying...' : 'Verify OTP'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
