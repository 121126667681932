import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import ReceiptTemplate1 from './ReceiptTemplate1';
import ReceiptTemplate2 from './ReceiptTemplate2';
import ReceiptTemplate3 from './ReceiptTemplate3';
import ReceiptTemplate4 from './ReceiptTemplate4';
import ReceiptTemplate5 from './ReceiptTemplate5';
import ReceiptTemplate6 from './ReceiptTemplate6';

const Receipt = ({ receiptId }) => {
  const [receiptData, setReceiptData] = useState(null);

  useEffect(() => {
    const fetchReceipt = async () => {
      try {
        const docRef = doc(db, "expenses", receiptId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setReceiptData(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error('Error fetching receipt', error);
      }
    };
    fetchReceipt();
  }, [receiptId]);

  if (!receiptData) {
    return <div>Loading...</div>;
  }

  const { receiptType } = receiptData;

  return (
    <div style={{background:'#f0f0f0', padding:'20px'}}>
      {(() => {
        switch (receiptType) {
          case 1:
            return <ReceiptTemplate1 receiptData={receiptData} />;
          case 2:
            return <ReceiptTemplate2 receiptData={receiptData} />;
          case 3:
            return <ReceiptTemplate3 receiptData={receiptData} />;
          case 4:
            return <ReceiptTemplate4 receiptData={receiptData} />;
          case 5:
            return <ReceiptTemplate5 receiptData={receiptData} />;
          case 6:
            return <ReceiptTemplate6 receiptData={receiptData} />;
          // Add more cases for other receipt types
          default:
            return <div>Invalid receipt type</div>;
        }
      })()}
    </div>
  );
};

export default Receipt;
