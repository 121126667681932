import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
const ReceiptTemplate6 = ({ receiptData }) => {
  const { receiptId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
      const [data, setData] = useState(receiptData || null);
    
      useEffect(() => {
        if (!receiptData) {
          const fetchReceipt = async () => {
            try {
              const docRef = doc(db, 'expenses', receiptId);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                const receiptData = docSnap.data();
                setData(receiptData);
                setHtmlContent(generateHtmlContent(receiptData));
              } else {
                console.error('No such document!');
              }
            } catch (error) {
              console.error('Error fetching receipt', error);
            }
          };
          fetchReceipt();
        } else {
          setHtmlContent(generateHtmlContent(receiptData));
        }
      }, [receiptId, receiptData]);
    
      if (!data) {
        return <div>Loading...</div>;
      }

  const generateHtmlContent = (receiptData) => {
    const {
      items, subtotal, total, currency, tax, adjustments,
      userData, customizationData, addressData, uniqueTaxRate, timestamp
    } = receiptData;

    const hasTaxableItems = items.some((item) => item.enableItemTax);
    const colspanValue = hasTaxableItems ? 4 : 3;

    const renderItemsTable = items.map((item, index) => (
      `<tr key=${index}>
          <td>${item.name}</td>
          <td>${item.quantity} ${item.measurementUnit}</td>
          <td>${currency.symbol}${item.unitPrice.toFixed(2)}</td>
          ${hasTaxableItems ? `<td>${item.itemTax}%</td>` : ''}
          <td class="text-end">${currency.symbol}${(item.quantity * item.unitPrice).toFixed(2)}</td>
      </tr>`
    )).join('');

    const renderAdjustments = adjustments.map((adjustment, index) => (
      `<tr key=${index}>
          <td colSpan=${colspanValue} class="text-end border-bottom-0">
              <strong>${adjustment.adjustmentName}${adjustment.adjustmentType === '+%' || adjustment.adjustmentType === '-%' ? ` ${adjustment.value}%` : ''}:</strong>
          </td>
          <td class="text-end border-bottom-0">
              ${currency.symbol}${adjustment.adjustmentValue}
          </td>
      </tr>`
    )).join('');

    const timestampToDate = (timestamp) => {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp && timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return 'Invalid Date';
      }
      return date.toLocaleDateString();
    };

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Receipt</title>
          <link rel="stylesheet" type="text/css" href="https://harshithvemulas.github.io/pbcss/main-style.css">
          <style>
            .modern-invoice .row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .modern-invoice .col-sm-6 {
                flex: 0 0 48%;
            }
            .extra-padding {
                min-height: 100px;
            }
            * { print-color-adjust:exact !important; }
          </style>
          <script>
            document.addEventListener("DOMContentLoaded", function() {
              const numItems = document.querySelectorAll('.table.table-striped tbody tr').length;
              if (numItems < 4) {
                document.querySelector('.extra-padding').style.paddingBottom = '50px';
              }
            });
          </script>
      </head>
      <body class="section-bg-one">
          <main class="main-wrapper position-relative">
              <div class="modern-invoice2 w-100" id="download-section">
                  <!-- invoice Top -->
                  <div class="card-headers d-flex flex-wrap align-items-center justify-content-sm-between">
                      <div class="left-invoice">
                          ${customizationData?.displayMerchantLogo && customizationData?.userImg ? 
                          `<a href="index.html"><img src="${customizationData.userImg}" title="Invoice" alt="Invoice" style="max-width: 100%; max-height: 60px;"></a>` : ''}
                      </div>
                      <div class="right-invoice">
                          <h4 class="text-30 mb-0 mt-0 text-white text-end">Receipt</h4>
                          <span class="status d-block text-capitalize text-white"> <strong>${timestampToDate(timestamp)}</strong></span>
                          <div class="shape"></div>
                      </div>
                  </div>
                  <div class="modern-invoice">
                      <!-- invoice Description -->
                      <div class="card-body">
                          <div class="row">
                              <div class="col-sm-6">
                                  <div class="invoice-details mb-20">
                                      <h5 class="mb-10 text-18 text-capitalize">From:</h5>
                                      <div class="invoice-details-inner mt-2">
                                          <li><b>${customizationData?.merchantname}</b></li>
                                          ${customizationData?.displayMerchantEmail && customizationData?.email ? `<li><b>Email:</b> ${customizationData.email}</li>` : ''}
                                          ${customizationData?.displayMerchantPhone && customizationData?.phone?.phoneNumber ? `<li><b>Phone:</b> ${customizationData.phone.phoneNumber}</li>` : ''}
                                          ${customizationData?.displayMerchantAddress && addressData ? `
                                          <li><b>Address:</b> ${addressData.line1}, ${addressData.line2}</li>
                                          <li>${addressData.city}, ${addressData.state}, ${addressData.country}</li>
                                          <li>${addressData.zipcode}</li>` : ''}
                                      </div>
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="invoice-details mb-20">
                                      <h5 class="mb-10 text-18 text-capitalize">To:</h5>
                                      <div class="invoice-details-inner mt-2">
                                          ${userData?.fname} ${userData?.lname}<br>
                                          ${userData?.email ? `<li>Email: ${userData.email}</li>` : ''}
                                          ${userData?.phone?.countryCode && userData?.phone?.phoneNumber ? `Phone: ${userData.phone.countryCode} ${userData.phone.phoneNumber}` : ''}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- invoice Table -->
                      <div class="table-responsive invoice-table mb-4">
                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th class="text-white">Name</th>
                                      <th class="text-white">Qty</th>
                                      <th class="text-white">Rate</th>
                                      ${hasTaxableItems ? `<th class="black-bg text-white">Tax</th>` : ''}
                                      <th class="black-bg text-end text-white">Amount</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  ${renderItemsTable}
                              </tbody>
                              <tfoot>
                                  <tr class="card-footer">
                                      <td colspan="${colspanValue}" class="text-end"><strong>Sub Total:</strong></td>
                                      <td class="text-end">${currency.symbol}${subtotal}</td>
                                  </tr>
                                  ${renderAdjustments}
                                  <tr>
                                      <td colspan="${colspanValue}" class="text-end"><strong>Tax${uniqueTaxRate !== null ? ` (${uniqueTaxRate}%)` : ''}:</strong></td>
                                      <td class="text-end">${currency.symbol}${tax}</td>
                                  </tr>
                                  <tr>
                                      <td colspan="${colspanValue}" class="text-end border-bottom-0"><strong>Total:</strong></td>
                                      <td class="text-end border-bottom-0">${currency.symbol}${total}</td>
                                  </tr>
                              </tfoot>
                          </table>
                      </div>
                      <div class="row">
                          <div class="col-sm-12 mb-10"></div>
                          <div class="extra-padding"></div>
                          <div>
                              <h5 class="mb-2 text-title font-700">Important:</h5>
                              ${customizationData?.Terms ? `<p><span class="text-bold text-center">Terms:&nbsp;</span> ${customizationData.Terms}</p>` : ''}
                              ${customizationData?.returnPolicy ? `<p><span class="text-bold text-center">Return policy:&nbsp;</span> ${customizationData.returnPolicy}</p>` : ''}
                              <p>This is an electronic generated invoice so doesn't require any signature.</p>
                          </div>
                      </div>
                  <div class="footer-shape">
                      <svg viewBox="0 0 500 200">
                          <path d="M 0 50 C 150 50 300 0 500 80 L 500 0 L 0 0" fill="#f73e4c"></path>
                          <path d="M 0 50 C 150 50 330 -30 500 50 L 500 0 L 0 0" fill="#000"></path>
                          <path d="M 0 50 C 215 50 250 0 500 100 L 500 0 L 0 0" fill="#f73e4c" opacity="0.1"></path>
                      </svg>
                  </div>
                                    </div>
              </div>
          </main>
      </body>
      </html>`;
  };


  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      {/* <ReceiptActions htmlContent={htmlContent} fileName={`receipt_${receiptId}.pdf`} /> */}
    </div>
  );
};

export default ReceiptTemplate6;
