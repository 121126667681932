import React from 'react';
import { useNavigate } from 'react-router-dom';

const TransactionComplete = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Transaction Complete</h1>
      <p>The receipt has been sent successfully!</p>
      <button
        onClick={() => navigate('/dashboard')}
        style={{ padding: '10px 20px', marginTop: '20px' }}
      >
        Back to Dashboard
      </button>
    </div>
  );
};

export default TransactionComplete;
