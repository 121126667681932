import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-dark text-white py-4">
      <Container>
        <Row>
          <Col className="text-center">
            <p>&copy; {new Date().getFullYear()} PullBill. All rights reserved.</p>
            <p>
              <Link to="/privacyPolicy" className="text-white">Privacy Policy</Link> | 
              <Link to="/TermsOfService" className="text-white"> Terms of Service</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
