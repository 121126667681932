import React from 'react';
import HeroSection from '../components/HeroSection';
import AboutPullBill from '../components/AboutPullBill';
import FeaturesSection from '../components/FeaturesSection';
import MerchantInfo from '../components/MerchantInfo';
import ContactSection from '../components/ContactSection';
import CountersSection from '../components/CountersSection';
import { Helmet } from 'react-helmet';

const Home = () => {
  <div>
  <Helmet>
    <title>PullBill - Home</title>
    <meta name="description" content="Welcome to PullBill, the ultimate digital receipt management system. Simplify and secure your purchase tracking with AI-powered insights." />
    <meta name="keywords" content="PullBill, digital receipts, receipt management, AI, finance, spending analysis" />
    </Helmet>
    </div>

    return (
      <div>
        <HeroSection />
        <CountersSection />
        <div id="about">
          <AboutPullBill />
        </div>
        <div id="features">
          <FeaturesSection />
        </div>
        <MerchantInfo />
        <ContactSection />
      </div>
    );
  };
  

export default Home;
