import React from "react";

const SquareIntegration = ({ merchantUuid }) => {
  const handleConnectSquare = () => {
    if (!merchantUuid) {
      alert("Merchant UUID is missing!");
      return;
    }

    const clientId = process.env.REACT_APP_SQUARE_CLIENT_ID; // Add to your .env file
    const redirectUri = encodeURIComponent(process.env.REACT_APP_SQUARE_REDIRECT_URI); // Add to .env file
    const state = encodeURIComponent(merchantUuid); // To track which merchant initiated the OAuth flow
    const squareAuthUrl = `https://squareupsandbox.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=ORDERS_READ+PAYMENTS_READ+MERCHANT_PROFILE_READ&state=${state}`;

    window.location.href = squareAuthUrl; // Redirect user to Square OAuth page
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Square Integration</h1>
      <p>Connect your Square account to integrate PullBill with your Square POS.</p>
      <button onClick={handleConnectSquare} style={{ padding: "10px 20px" }}>
        Connect with Square
      </button>
    </div>
  );
};

export default SquareIntegration;
