import React from 'react';
import { useParams } from 'react-router-dom';
import Receipt from '../components/Receipt';

const ReceiptPage = () => {
  const { id } = useParams();
  return <Receipt receiptId={id} />;
};

export default ReceiptPage;
