import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import backgroundImage from '../assets/bgImage.jpeg'
import { Link } from 'react-scroll';

const HeroSectionWrapper = styled.div`
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  color: white;
  position: relative;
  text-align: center;
  height: 70vh;  /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  .container {
    z-index: 1;
    position: relative;
  }

  h1, p {
    color: white;
  }

  .btn {
    margin: 5px;
  }
`;

const HeroSection = () => {
  return (
    <HeroSectionWrapper>
      <Container className="text-center text-white py-5">
        <Row>
        <Col>
            <h1>Welcome to PullBill</h1>
            <p>Your ultimate solution for digital receipts and merchant services.</p>
            <Link to="about" smooth={true} duration={500}>
              <Button variant="light" size="lg" className="m-2">Get Started</Button>
            </Link>
            <Link to="features" smooth={true} duration={500}>
              <Button variant="outline-light" size="lg" className="m-2">Learn More</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </HeroSectionWrapper>
  );
};

export default HeroSection;